import { TableCell, TableRow } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TableComponent from "../../components/Tables";
import { STATE } from "../../constants";
import { ApiGet, ApiPost, ApiPut, ApiDelete } from "../../helper/API/ApiData";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { TieredMenu } from "primereact/tieredmenu";
import { toast } from "react-toastify";
import { ConfirmDialog } from "primereact/confirmdialog";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Pagination from "../../components/Tables/pagination";
import moment from 'moment'
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";

// import Header from './components/header'
// import Sidebar from './components/sidebar'
function LoginCodeList() {
  const loginCodeListData = {
    bank_name: "",
    dsa_name: "",
    dsa_code: "",
    product: "",
    city: "",
    sm_name: "",
    email: "",
    phone: "",
    margin: "",
    // adjustment_ratio: "",
    // adjustment_ratio_name: "",
    bank_name_error: null,
    dsa_name_error: null,
    dsa_code_error: null,
    product_error: null,
    city_error: null,
    sm_name_error: null,
    email_error: null,
    phone_error: null,
    margin_error: null,
    // adjustment_ratio_error: null,
    // adjustment_ratio_name_error: null,
  };

  const EditCodeListData = {
    id: "",
    bank_name: "",
    dsa_name: "",
    dsa_code: "",
    product: "",
    city: "",
    sm_name: "",
    email: "",
    phone: "",
    margin: "",
    // adjustment_ratio: "",
    // adjustment_ratio_name: "",
    bank_name_error: null,
    dsa_name_error: null,
    dsa_code_error: null,
    product_error: null,
    city_error: null,
    sm_name_error: null,
    email_error: null,
    phone_error: null,
    margin_error: null,
    // adjustment_ratio_error: null,
    // adjustment_ratio_name_error: null,
  };

  const columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "bank_name", label: "Bank/NBFC Name" },
    { id: "dsa_code", label: "DSA Code" },
    { id: "dsa_name", label: "DSA NAME" },
    { id: "product", label: "Product" },
  ];

  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);

  const accept = () => {
    deleteLoginCodeList(selectedRowData.current);
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };

  const [formData, setFormData] = useState<any>(loginCodeListData);
  const [editData, setEditData] = useState<any>(EditCodeListData);
  const [addCount, setCount] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [loginCodeListDetail, setLoginCodeListDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bankNameOptions, setBankNameOptions] = useState(null);
  const [productOptions, setProductNameOptions] = useState(null);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [permission, setPermission] = useState<any>({});
  const [paginator,setPaginator]=useState(true)
  const [showDialog, setShowDialog] = useState(false); 
  const [activeStatus, setActiveStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const op = useRef(null);
  const[exportType,setExportType]=useState('');
  const user = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination:true,
    search: "",
    filters: {
      bank_name: { value: null, matchMode: FilterMatchMode.IN },
      product: { value: null, matchMode: FilterMatchMode.IN },
    },
  });
  let date=new Date().toDateString();
  const [newdate,setDate]=useState(moment(date).format('DD-MM-YY'))

  useEffect(() => {
    Promise.all([getLoginCodeList(), fetchDropdownOptions()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [lazyState,paginator]);

  const getLoginCodeList = () => {
    setLoader(true);
    let modifiedLazyState;
    if(paginator){
      modifiedLazyState = { ...lazyState, limit: lazyState.rows ,pagination:true};
    }
    else{ modifiedLazyState = { ...lazyState, limit: lazyState.rows ,pagination:false};
  }    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    ApiPost(`loginCodeList/get-login-codelist`, finalModifiedLazyState)
      .then((res: any) => {
        setTotalRecords(Number(res.data.count[0].count));
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: lazyState.first + index + 1,
            bank_name: item.bank_name,
            dsa_code: item.dsa_code,
            dsa_name: item.dsa_name,
            product: item.product,
            allow_advance_request:item.allow_advance_request
          };
        });
        setLoginCodeListDetail(temp);
        const tempPermission = {
          [PERMISSION_TYPE.ADD_LOGIN_CODE]: userPermission(
            user,
            PERMISSION_TYPE.ADD_LOGIN_CODE
          ),
          [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
          [PERMISSION_TYPE.STAFF_OPS]: userPermission(user, PERMISSION_TYPE.STAFF_OPS),
        };
        setPermission(tempPermission);
        if(!paginator)
          {
          if(exportType == 'pdf'){
          const data=res.data.result;
          const doc = new jsPDF('landscape', 'mm', 'a4');
                       
          let width = doc.internal.pageSize.width;
          let height = doc.internal.pageSize.height;

            const img = new Image();
            img.src = 'assets/logo.png'; 
            img.onload =function(){
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.globalAlpha = 0.2;
  
             ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              const watermarkBase64 = canvas.toDataURL("image/png");
  
              const maxWidth = width * 0.5;
              const maxHeight = height * 0.5; 
              let newWidth = img.width;
              let newHeight = img.height;
  
              if (newWidth > maxWidth || newHeight > maxHeight) {
                const widthRatio = maxWidth / newWidth;
                const heightRatio = maxHeight / newHeight;
                const scale = Math.min(widthRatio, heightRatio);
        
                newWidth = newWidth * scale;
                newHeight = newHeight * scale;
            }
        
            // Calculate the center position
            let xPos = (width - newWidth) / 2;
            let yPos = (height - newHeight) / 2;

            const title = "Logincodelist list";
            doc.setFontSize(18);
            doc.setFont("helvetica");
            const textWidth = doc.getTextDimensions(title).w;
            doc.text(title, (width - textWidth) / 2, 10);
                        // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');
                        autoTable( doc,{ head: [columns.map(col => col.label)],
                        body: data.map((product,index)=> columns.map((col) =>{
                              if(col.id === 'SRNO'){
                                return  index+1;
                              }
                              else
                               {
                              return   product[col.id]
                              }
                          })),styles: {
                                  fontSize:10,
                                  cellPadding: 3,
                                  textColor:[0,0,0]
                              },
                              headStyles: {
                                  fillColor: [52, 152, 219],
                                  textColor: [255, 255, 255]
                              },
                              margin: { top: 15 },
                              theme: 'grid',
                              didDrawPage: function (data) {
                                doc.addImage(watermarkBase64, 'PNG',xPos, yPos, newWidth, newHeight);
          
                          }}
                    );
                   
                    doc.save(`Logincodelist_${newdate}.pdf`);
                  }
                  setPaginator(true)  ;               
        setLoader(false);
      
        }
        
      else{

        const csvRows = [];
        const data = res.data.result;
        const modifiedData = data.map((product, index) => {
          // Create a row with custom logic for "SRNO"
          const row = columns.map(col => {

            if (col.id === "SRNO") {
              return index + 1; // Add serial number
            }
             else {
              return product[col.id]; // Add product data
            }
          });
      
          return row;
        });
        csvRows.push(columns.map(col => col.label));

        modifiedData.forEach(row => {
          csvRows.push(
            row
          );
        });

    const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Logincodelist_${newdate}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
        setPaginator(true)  ;               
        setLoader(false);
      
      }
    }
      })
      .catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.ADD_LOGIN_CODE]: userPermission(
            user,
            PERMISSION_TYPE.ADD_LOGIN_CODE
          ),
          [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
          [PERMISSION_TYPE.STAFF_OPS]: userPermission(user, PERMISSION_TYPE.STAFF_OPS),

        };
        setPermission(tempPermission);
      });
      setLoader(false);

  };

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["page"] = 0;
    setlazyState(event);
  };

  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const exportPdf = () => {
    setPaginator(false);
    getLoginCodeList()        
    setExportType('pdf');
       
};

const exportCSV = () => {
  setExportType('csv');
  setPaginator(false);
  getLoginCodeList()
};
  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div>
        {/* <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
        <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
        <OverlayPanel ref={op}>
                <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                    { (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.STAFF_OPS]) && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={() => exportCSV()}>Csv</option>
                                        )}
                                        </OverlayPanel>
        </Button>
        </div>
        <div>
        <span>
          <InputText
            className="mr-2 ml-3"
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Keyword Search"
          />
          <Button icon="pi pi-search" onClick={handleSearchClick} />
        </span>
        </div> 
      </div>
    );
  };

  const header = renderHeader();

  const menuRef = useRef(null);
  const selectedRowData = useRef(null);

  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Edit") {
      setEditOpen(true);
      const data = {
        id: selectedRowData.current.id,
        bank_name: selectedRowData.current.bank_name,
        dsa_name: selectedRowData.current.dsa_name,
        dsa_code: selectedRowData.current.dsa_code,
        product: selectedRowData.current.product,
      };
      setEditData(data);
    } else if (itemLabel === "Delete") {
      setDeleteConfirmVisible(true);
    }
  };

  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    return [
      { label: "Edit", command: () => handleMenuItemClick("Edit") },
      { label: "Delete", command: () => handleMenuItemClick("Delete") },
    ];
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch checked={rowData.allow_advance_request == true ? true : false} onChange={() => handleToggle(rowData)} />
      </div>
    );
  };
  const handleToggle = (user) => {
    setActiveStatus(user.allow_advance_request == true ? true : false);
    setSelectedUser(user.id);
    selectedRowData.current=user;
    setShowDialog(true);
  };
  const cancelToggle = () => {
    setShowDialog(false);
    setSelectedUser(null);
  };
  const confirmToggle = async () => {
    let request;

    if (selectedUser) {
      if(selectedRowData.current.allow_advance_request == true){
        request=false
      }
      else{
        request=true
      }
      ApiPut(`loginCodeList/edit-advance-status?id=${selectedUser}&status=${request}`).then((res: any) => {
        if (res && res.success) {
          toast.success(res.data.message, {
            position: "top-right",
            theme: "colored"
          });
          let newUser= loginCodeListDetail.map((user)=>
            selectedRowData.current.id == user.id ? {...user, allow_advance_request:activeStatus ? false : true} : user
          );
          setLoginCodeListDetail(newUser)
          setShowDialog(false);
          setSelectedUser(null);
        }
      }).catch((error)=>{
        //  console.log(error)
      });
    //   if(user.role == "staff_operations"){
    //  ApiPut(`loginCodeList/edit-advance-status?id=${selectedUser}&status=${request}`).then((res: any) => {
    //     if (res && res.success) {
    //       toast.success(res.data.message, {
    //         position: "top-right",
    //         theme: "colored"
    //       });
    //       let newUser= loginCodeListDetail.map((user)=>
    //         selectedRowData.current.id == user.id ? {...user, allow_advance_request:activeStatus ? false : true} : user
    //       );
    //       setLoginCodeListDetail(newUser)
    //       setShowDialog(false);
    //       setSelectedUser(null);
    //     }
    //   }).catch((error)=>{
    //     //  console.log(error)
    //   }); }
    //   else{
    //   ApiPut(`loginCodeList/edit-advance-status?id=${selectedUser}&status=${request}`).then((res: any) => {
    //     if (res && res.success) {
    //       toast.success(res.data.message, {
    //         position: "top-right",
    //         theme: "colored"
    //       });
    //       let newUser= loginCodeListDetail.map((user)=>
    //         selectedRowData.current.id == user.id ? {...user, allow_advance_request:activeStatus ? false : true} : user
    //       );
    //       setLoginCodeListDetail(newUser)
    //       setShowDialog(false);
    //       setSelectedUser(null);
    //     }
    //   }).catch((error)=>{
    //     //  console.log(error)
    //   });  }    
    }
  };
  // Assuming you have a function to fetch dropdown options asynchronously
  const fetchDropdownOptions = () => {
    ApiGet(`options/logincodelist`).then((res: any) => {
      const banks = convertToObjectArray(res.data.banks);
      const products = convertToObjectArray(res.data.products);

      setBankNameOptions(banks);
      setProductNameOptions(products);
    });
  };

  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key],
      value: key,
    }));
  };
  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };

  const addLoginCodeList = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }

    if (formData.bank_name == "") {
      updatedForm = {
        ...updatedForm,
        bank_name_error: "Please enter a bank name",
      };
    }

    if (formData.dsa_name == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please enter a dsa name",
      };
    }

    if (formData.dsa_code == "") {
      updatedForm = {
        ...updatedForm,
        dsa_code_error: "Please enter a dsa code",
      };
    }
    if (formData.product == "") {
      updatedForm = { ...updatedForm, product_error: "Please enter a Product" };
    }

    setFormData(updatedForm);
    if (
      updatedForm.bank_name_error == null &&
      updatedForm.dsa_name_error == null &&
      updatedForm.dsa_code_error == null &&
      updatedForm.product_error == null &&
      updatedForm.city_error == null &&
      updatedForm.margin_error == null
    ) {
      let {
        bank_name_error,
        dsa_name_error,
        dsa_code_error,
        product_error,
        city_error,
        email_error,
        sm_name_error,
        phone_error,
        margin_error,
        ...payload
      } = updatedForm;

      if(user.role == "staff_operations"){
        
      const finalPayload={
        changes:payload,
        title: `Add Logincodelist `,
        method:'Post',
        entity_id:null,
        url:'loginCodeList/add-codelist',
        entity_type:'logincodelist',
		    operation:'add'
      }  
      ApiPost("ApprovalRequest/approval-request", finalPayload)
        .then((res: any) => {
          setFormData(loginCodeListData);
          getLoginCodeList();
          setAddOpen(false);
          setLoader(false)
          toast.success(res.message, {
            position: "top-right",
            theme: "colored",
          });
        })
        .catch((error) => {
          console.log(error);
        });
      }
      else{
        ApiPost("loginCodeList/add-codelist", payload)
        .then((res: any) => {
          setFormData(loginCodeListData);
          getLoginCodeList();
          setAddOpen(false);
          toast.success('Added Successfully', {
            position: "top-right",
            theme: "colored",
          });
          setAddOpen(false)
          setLoader(false)

        })
        .catch((error) => {
          console.log(error);
        });
    }}
  };

  const deleteLoginCodeList = (row: any) => {
    if(user.role == "staff_operations"){
      
    const finalPayload={
      changes:{
      bank_name:row.bank_name,
      dsa_name:row.dsa_name,
      title:"Delete this logincodelist list",
      product_name:row.product
      },
      title: `Delete Logincodelist `,
      method:'Put',
      entity_id:row.id,
      url:'loginCodeList/delete-codelist',
      entity_type:'logincodelist',
		  operation:'delete'
    }  
    ApiPost("ApprovalRequest/approval-request",finalPayload)
      .then((res: any) => {
        setFormData(loginCodeListData);
        getLoginCodeList();
        toast.success(res.data, {
          position: "top-right",
          theme: "colored",
        });
      })
      .catch(() => {
        console.log("Something went wrong");
      });
    }
    else{

      ApiPut("loginCodeList/delete-codelist?id=" + row.id, {})
      .then((res: any) => {
        setFormData(loginCodeListData);
        getLoginCodeList();
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      })
      .catch(() => {
        console.log("Something went wrong");
      });}
  };

  const editLoginCodeList = (id: any) => {
    let updatedForm = { ...editData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }

    if (editData.bank_name == "") {
      updatedForm = {
        ...updatedForm,
        bank_name_error: "Please enter a bank name",
      };
    }
    if (editData.dsa_name == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please enter a dsa name",
      };
    }

    if (editData.dsa_code == "") {
      updatedForm = {
        ...updatedForm,
        dsa_code_error: "Please enter a dsa code",
      };
    }
    if (editData.product == "") {
      updatedForm = { ...updatedForm, product_error: "Please enter a Product" };
    }

    setEditData(updatedForm);
    if (
      updatedForm.bank_name_error == null &&
      updatedForm.dsa_name_error == null &&
      updatedForm.dsa_code_error == null &&
      updatedForm.product_error == null &&
      updatedForm.city_error == null &&
      updatedForm.margin_error == null
    ) {
      let {
        bank_name_error,
        dsa_name_error,
        dsa_code_error,
        product_error,
        city_error,
        email_error,
        sm_name_error,
        phone_error,
        margin_error,
        ...payload
      } = updatedForm;
      delete payload.id;

      if(user.role == "staff_operations"){
        
      const finalPayload={
        changes:payload,
        title: `Edit Logincodelist `,
        method:'Put',
        entity_id:id,
        url:'loginCodeList/edit-codelist',
        entity_type:'logincodelist',
		    operation:'update'
      }  
      ApiPost("ApprovalRequest/approval-request", finalPayload)
        .then((res: any) => {
          toast.success(res.data, {
            position: "top-right",
            theme: "colored",
          });
          getLoginCodeList();
          setEditOpen(false);
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        ApiPut("loginCodeList/edit-codelist?id=" + id, payload)
        .then((res: any) => {
          toast.success('Updated Successfully', {
            position: "top-right",
            theme: "colored",
          });
          getLoginCodeList();
          setEditOpen(false);
          console.log(loader)

        })
        .catch((error) => {
          console.log(error);
        });
    }}
  };

  const bankNameRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={bankNameOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const productRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={productOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  
  return (
    <div className="overflow-x-hidden">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* START::MAIN CONTENT */}
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  Login Code List
                </h1>

                {permission[PERMISSION_TYPE.ADD_LOGIN_CODE] && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpen(true);
                        }}
                      >
                        Add Code List Data
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <DataTable
              value={loginCodeListDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator={paginator}
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              emptyMessage="No records found."
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              
            >
              <Column field="SRNO" header="SR.NO." />
              <Column
                field="bank_name"
                header="Bank/NBFC Name"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={bankNameRowFilterTemplate}
              />
              <Column field="dsa_code" header="DSA Code" />
              <Column field="dsa_name" header="DSA NAME" />
              <Column
                field="product"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productRowFilterTemplate}
              />
              {permission[PERMISSION_TYPE.ADMIN] &&<Column
              field="Status"
              header="Status"
              body={statusBodyTemplate}/>}
              {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) ? (
                <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />
              ) : null}
            </DataTable>
          </div>
          <Dialog
        header={`${activeStatus ? 'Dectivate' : 'Activate'  } user `}
        visible={showDialog}
        onHide={cancelToggle}
        footer={
          <div>
            <Button label="No" icon="pi pi-times" onClick={cancelToggle} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={confirmToggle} autoFocus />
          </div>
        }
      >{ activeStatus && (<div>
            <p>  Are you sure you want to disable the user. </p>
            <p>  Note:- Advance Payment Request will be disabled for this logincodelist</p>
      </div>)}
      { !activeStatus && (<div>
            <p>  Are you sure you want to enable the user. </p>
            <p>  Note:- Advance Payment Request will be enabled for this logincodelist</p>
      </div>)}

       
      </Dialog>

          {/* </main> */}

          {/* Start::Add Disbursement Data Modal */}
          <div
            className={`tw-modal ${addOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add CodeList Data</div>
                <button onClick={() => setAddOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label
                      htmlFor="bank_name"
                      className={`input-label ${
                        formData.bank_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Bank Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="bank_name"
                      className={`input-wrap ${
                        formData.bank_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="bank_name"
                        value={formData.bank_name}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            bank_name: e.target.value,
                            bank_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Bank Name"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.bank_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="dsa_name"
                      className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}
                    >
                      DSA Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="dsa_name"
                      className={`input-wrap ${
                        formData.dsa_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="dsa_name"
                        value={formData.dsa_name}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            dsa_name: e.target.value,
                            dsa_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter DSA Name"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="dsa_code"
                      className={`input-label ${
                        formData.dsa_code_error ? "text-red-500" : ""
                      }`}
                    >
                      DSA Code<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="dsa_code"
                      className={`input-wrap ${
                        formData.dsa_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="dsa_code"
                        value={formData.dsa_code}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            dsa_code: e.target.value,
                            dsa_code_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter DSA Code"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.dsa_code_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="product"
                      className={`input-label ${
                        formData.product_error ? "text-red-500" : ""
                      }`}
                    >
                      Product<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="product"
                      className={`input-wrap ${
                        formData.product_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/product.svg" alt="" />
                      <input
                        type="text"
                        name="product"
                        value={formData.product}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            product: e.target.value,
                            product_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Product"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.product_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={addLoginCodeList}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => setAddOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* End::Add Disbursement Data Modal */}
          {/* END::MAIN CONTENT */}

          <div
            className={`tw-modal ${editOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit CodeList Data</div>
                <button onClick={() => setEditOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label
                      htmlFor="bank_name"
                      className={`input-label ${
                        editData.bank_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Bank Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="bank_name"
                      className={`input-wrap ${
                        editData.bank_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="bank_name"
                        value={editData.bank_name}
                        onChange={(e: any) => {
                          setEditData({
                            ...editData,
                            bank_name: e.target.value,
                            bank_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Bank Name"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editData.bank_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="dsa_name"
                      className={`input-label ${
                        editData.dsa_name_error ? "text-red-500" : ""
                      }`}
                    >
                      DSA Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="dsa_name"
                      className={`input-wrap ${
                        editData.dsa_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="dsa_name"
                        value={editData.dsa_name}
                        onChange={(e: any) => {
                          setEditData({
                            ...editData,
                            dsa_name: e.target.value,
                            dsa_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter DSA Name"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editData.dsa_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="dsa_code"
                      className={`input-label ${
                        editData.dsa_code_error ? "text-red-500" : ""
                      }`}
                    >
                      DSA Code<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="dsa_code"
                      className={`input-wrap ${
                        editData.dsa_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="dsa_code"
                        value={editData.dsa_code}
                        onChange={(e: any) => {
                          setEditData({
                            ...editData,
                            dsa_code: e.target.value,
                            dsa_code_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter DSA Code"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editData.dsa_code_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="product"
                      className={`input-label ${
                        editData.product_error ? "text-red-500" : ""
                      }`}
                    >
                      Product<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="product"
                      className={`input-wrap ${
                        editData.product_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/product.svg" alt="" />
                      <input
                        type="text"
                        name="product"
                        value={editData.product}
                        onChange={(e: any) => {
                          setEditData({
                            ...editData,
                            product: e.target.value,
                            product_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Product"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editData.product_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={() => editLoginCodeList(editData.id)}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => setEditOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <ConfirmDialog
            // group="declarative"
            visible={deleteConfirmVisible}
            onHide={() => setDeleteConfirmVisible(false)}
            message="Are you sure you want to delete?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
        </>
      )}
    </div>
  );
}

export default LoginCodeList;
