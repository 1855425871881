import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { userPermission } from '../../helper/permission';
import { RootState } from '../../redux/store';
import { PERMISSION_TYPE } from './../../helper/permission';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { TieredMenu } from 'primereact/tieredmenu';
import Select from "react-select";

function CompanyPayoutGrid() {
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [loader, setLoader] = useState(true);
    const [permission, setPermission] = useState<any>({});
    const [countData, setCountData] = useState<any>([]);
    const user = useSelector((state: RootState) => state?.authUser?.userData?.user);
    const [totalRecords, setTotalRecords] = useState(0);
    const [payoutData, setPayoutData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [bankNameOptions, setBankNameOptions] = useState<any>([]);
    const [productOptions, setProductNameOptions] = useState(null);
    const [codeData, setCodeData] = useState<any>([]);
    const [editOpen, setEditOpen] = useState(false);
    const [normalSlot, setNormalSlot] = useState({
        normal: "",
        normal_payout_error: null,
        capping: "",
        capping_error: null,
        logincodelist_id: "",
        user_id: "",
      });
      const loginCodeListData = {
        logincodelistid: "",
        normal_payout: "",
        capping: "",
        logincodelistid_error: null,
        normal_payout_error: null,
        capping_error: null,
      };
      const [addPayloadModel, setAddPayloadModel] = useState(false);
      const [formData, setFormData] = useState<any>(loginCodeListData);
      const [historyModel, setHistoryModel] = useState(false);
      const [historyData, setHistoryData] =useState([]);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: "",
        pagination:true,
        filters: {
          bank_name: { value: null, matchMode: FilterMatchMode.IN },
          product_name: { value: null, matchMode: FilterMatchMode.IN },
        },
      });
    
      useEffect(() => {
        const tempPermission = {
          [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
          [PERMISSION_TYPE.STAFF]: userPermission(user, PERMISSION_TYPE.STAFF),
          [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(user, PERMISSION_TYPE.BOOKING_PARTNER),
    
          
        };
        setPermission(tempPermission);
      }, [user]);
      const onPage = (event) => {
        let filter = { ...lazyState };
        filter = event;
        filter["search"] = lazyState.search;
        setlazyState(filter);
      };
    
      const onFilter = (event) => {
        event["first"] = 0;
        event["page"] = 0;
        setlazyState(event);
      };
    
      const onGlobalFilterChange = (e) => {
        const value = e;
        let filter = { ...lazyState };
    
        filter["search"] = value;
    
        setlazyState(filter);
      };
    
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          onGlobalFilterChange(searchValue);
        }
      };
    
      const handleSearchClick = () => {
        onGlobalFilterChange(searchValue);
      };
    
      const handleInputChange = (event) => {
        setSearchValue(event.target.value);
      };

      const menuRef = useRef(null);
  const selectedRowData = useRef(null);
      const handleEllipsisClick = (event, rowData) => {
        event.stopPropagation(); // Prevent event from bubbling up
        selectedRowData.current = rowData;
        if (menuRef.current) {
          menuRef.current.toggle(event);
        }
      };
      const handleMenuItemClick = (itemLabel) => {
        if (itemLabel === "Edit") {
          setEditOpen(true);
          const data = {
            normal: selectedRowData.current.normal_payout,
            normal_payout_error: null,
            capping: selectedRowData.current.capping,
            capping_error: null,
            logincodelist_id: selectedRowData.current.logincodelistid,
            user_id: selectedRowData.current.id,
          };
          setNormalSlot(data);
        } else if (itemLabel === "View History") {
            handleOpenHistoryModel(selectedRowData.current.logincodelistid);
          }
      };
      const generateMenuItems = () => {
          const menuItems = [
            { label: "Edit", command: () => handleMenuItemClick("Edit") },
            {
                label: "View History",
                command: () => handleMenuItemClick("View History"),
              },
          ];
    
              
        return menuItems;
      };

      const handleOpenHistoryModel = (id: any) => {
        setHistoryModel(true);
        try {
          ApiGet(`payoutGrid/get-company-viewhistory?logincodlist_id=${id}`).then(
                  (res: any) => {
                    if (res?.data?.data) {
                      setHistoryData(res.data.data);
                    }
                  }
                );
          
        } catch (ex) {
          // toast.error("something went wrong", {
          //   position: "top-right",
          //   theme: "colored"
          // })
        }
      };
      const actionBodyTemplate = (rowData) => {
        return (
          <div>
            <img
              src="../assets/menu-cirlce.svg"
              alt="menu-icon"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleEllipsisClick(e, rowData)}
            />
            <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
          </div>
        );
      };
    
      const renderHeader = () => {
        return (
          <div className="flex justify-end">
            <div>
            {/* <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
            {/* <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
            <OverlayPanel ref={op}>
                    <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                        { (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.STAFF_OPS]) && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={exportCSV}>Csv</option>
                                            )}
                                            </OverlayPanel>
            </Button> */}
            </div>
            <div>
            <span>
              <InputText
                className="mr-2 ml-3"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Keyword Search"
              />
              <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
            </div> 
          </div>
        );
      };
    
      const getCodeList = async () => {
        await ApiGet(`loginCodeList/option-codelist?source=companypayoutgrid`).then(
          (res: any) => {
            setCodeData(Object.values(res.data.result));
          }
        );
      };
      const codeDataOptions = codeData.map((c: any) => ({
        ...c,
        label: c.bank_name + " - " + c.product,
        value: c._id,
      }));
    const header = renderHeader();
    const handleInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9.]/g, "");
      };
      
    const getCompanyPayout=async ()=>{
        const modifiedLazyState = { ...lazyState, limit: lazyState.rows,pagination:true };
        delete modifiedLazyState.rows;
        delete modifiedLazyState['multiSortMeta'];
        delete modifiedLazyState['sortField'];
        delete modifiedLazyState['sortOrder'];

        // Function to replace empty arrays in the "value" field with null
        const replaceEmptyValueArrays = (obj) => {
            for (const key in obj) {
                if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object') {
                    replaceEmptyValueArrays(obj[key]);
                }
            }
            return obj;
        };

        // Call the function to replace empty arrays in the "value" field with null
        const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
        finalModifiedLazyState.page++;
        
            await ApiPost(`payoutgrid/get-company-payout`, finalModifiedLazyState)
                .then((res: any) => {
                  setTotalRecords(Number(res.data.count[0].count))
                    let temp = res?.data?.result.map((item: any, index: number) => {
                        return {
                          id: item._id,
                          SRNO: lazyState.first + index + 1,
                          product_name:item.product,
                         ...item
                        };
                      });
                    setPayoutData(temp)
                    setState({
                      ...state,
                      totalItems: res.data.count[0].count,
                    });
                }).
                catch((error) => {
                    
                });
    }
    
    const handleSave = () => {
        let updatedForm = { ...formData };
    
        if (formData.logincodelistid == "") {
          updatedForm = {
            ...updatedForm,
            logincodelistid_error: "Please select Code list",
          };
        }
        if (formData.normal_payout == "") {
          updatedForm = {
            ...updatedForm,
            normal_payout_error: "Please enter Normal Payout",
          };
        }
       
        setFormData(updatedForm);
    
        if (
          !updatedForm.logincodelistid_error &&
          !updatedForm.normal_payout_error &&
          !updatedForm.capping_error
        ) {         
          
          const payload = {
            logincodelistid: updatedForm.logincodelistid,
            normal_payout: updatedForm.normal_payout,
            capping_payout: updatedForm.capping,
          };
          ApiPost(`payoutgrid/add-company-payout`, payload).then((res: any) => {
            if (res && res.success) {
              toast.success("Added successfully", {
                position: "top-right",
                theme: "colored",
              });
            }
            getCompanyPayout();
            getCodeList()
            setFormData(loginCodeListData);
            setAddPayloadModel(false);
          });
        }
      };

      const editPayout = () => {
        let error = { ...normalSlot };
    
        if (!normalSlot.normal) {
          error = { ...error, normal_payout_error: "Please enter Normal Payout" };
        }
       
        setNormalSlot(error);
          if (
            !error.normal_payout_error 
          ) {
            
              ApiPut(`payoutgrid/edit-company-payout`, { ...normalSlot })
              .then((res: any) => {
                if (res && res.success) {
                  toast.success("Updated successfully", {
                    position: "top-right",
                    theme: "colored",
                  });
                }
                setEditOpen(false);
                getCompanyPayout()
             })
              .catch((error) => {
                console.log(error);
              });
          }
        } 
      
    const convertToObjectArray = (obj) => {
        return Object.keys(obj).map((key) => ({
          label: obj[key],
          value: key,
        }));
      };
    
    const fetchDropdownOptions = () => {
        setLoader(true);
        ApiGet(`options/payout`).then((res: any) => {
          const banks = convertToObjectArray(res.data.banks);
          const products = convertToObjectArray(res.data.products);
    
          setBankNameOptions(banks);
          setProductNameOptions(products);
        });
      };
    const handleProductChange = (selectedOption: any) => {
        setFormData({
          ...formData,
          logincodelistid: selectedOption ? selectedOption.value : "",
          logincodelistid_error: null,
        });
      };
    useEffect(() => {
        Promise.all([
            getCompanyPayout(),
            fetchDropdownOptions(),
            getCodeList(),
               ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [lazyState]);

    const bankNameRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={bankNameOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
    
      const productRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={productOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };

      const customStyles = (hasError) => ({
        control: (base, state) => ({
          ...base,
          borderColor: hasError ? "red" : base.borderColor,
          "&:hover": {
            borderColor: hasError ? "red" : base.borderColor,
          },
        }),
      });
    return (
        <div className="overflow-x-hidden p-6">
            {/* START::MAIN CONTENT */}
            {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <> 
        <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  Company Payout Grid
                </h1>
                
                  {!permission[PERMISSION_TYPE.STAFF] && <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddPayloadModel(true);
                       }}
                      >
                        Add Payout
                      </button>
                    </li>
                  </ul>}
              </div>
              
                          
            </div>
           
            {/* END::DASHBOARD HEADER */}
            <DataTable
              value={payoutData}
              lazy
              dataKey="id"
              showGridlines
              paginator
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              emptyMessage="No records found."
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
            >
              <Column field="SRNO" header="SR.NO." />
              <Column
                field="bank_name"
                header="Bank Name"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={bankNameRowFilterTemplate}
              />
              <Column
                field="product_name"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productRowFilterTemplate}
              />
              <Column
                field="normal_payout"
                header="Normal Payout Slab"
                
              />
              {!permission[PERMISSION_TYPE.STAFF] && <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />}
                         </DataTable>

<div className={`tw-modal ${editOpen ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Edit Payout Grid</div>
                  <button onClick={() => setEditOpen(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>
                <div className="tw-modal-body py-5">
                  <div className="col-span-2 space-y-6">
                    <div className="space-y-2">
                      <div className="w-full flex gap-5">
                        <div className="w-full space-y-2">
                          <label
                            htmlFor="normal_payout"
                            className={`input-label ${
                              normalSlot.normal_payout_error
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            Normal Payout<span style={{color:'red'}}>*</span>
                          </label>
                          <div
                            id="normal_payout"
                            className={`input-wrap ${
                              normalSlot.normal_payout_error
                                ? "border-red-500"
                                : ""
                            }`}
                          >
                            <input
                              type="number"
                              name="normal_payout"
                              value={normalSlot.normal}
                              onChange={(e: any) => {
                                setNormalSlot({
                                  ...normalSlot,
                                  normal: e.target.value,
                                  normal_payout_error: null,
                                });
                              }}
                              className="w-full text-sm"
                              placeholder="2.55"
                            />
                          </div>
                          <p className="text-red-500 text-sm">
                            {normalSlot.normal_payout_error}
                          </p>
                        </div>
                        
                      </div>
                    </div>
                    
                    
                  </div>               
                </div>
                <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={()=>{editPayout()}}
                  >
                    Save
                  </button>
                  <button
                    className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                    onClick={() => setEditOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className={`tw-modal ${addPayloadModel ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Add Payout</div>
                  <button onClick={() => {setAddPayloadModel(false)
                    setFormData(loginCodeListData)
                  }}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-6">
                  <div className="d-flex">
                    <div className="space-y-1">
                      <label
                        htmlFor="normal_payout"
                        className={`input-label ${
                          formData?.normal_payout_error ? "text-red-500" : ""
                        }`}
                      >
                        Normal Payout<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                        id="normal_payout"
                        className={`input-wrap ${
                          formData?.normal_payout_error ? "border-red-500" : ""
                        }`}
                      >
                        <input
                          type="text"
                          name="normal_payout"
                          value={formData.normal_payout}
                          onInput={handleInput}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              normal_payout: e.target.value,
                              normal_payout_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Normal Payout"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData?.normal_payout_error}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tw-modal-body py-6">
                  <div className="d-flex">
                    <div className="space-y-1">
                      <label
                        htmlFor="logincodelistid"
                        className={`input-label ${
                          formData?.logincodelistid_error ? "text-red-500" : ""
                        }`}
                      >
                        Code list<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                        id="logincodelistid"
                        className={`${
                          formData?.logincodelistid_error ? "border-red-500" : ""
                        }`}
                      >
                        <Select
                          options={codeDataOptions}
                          value={codeDataOptions.find(
                            (opt: any) => opt.value === formData.logincodelistid
                          )}
                          onChange={handleProductChange}
                          className="w-full text-sm py-2"
                          placeholder="Type to search..."
                          styles={customStyles(formData.logincodelistid_error)}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.logincodelistid_error}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={(e)=>{handleSave()}}
                  >
                    {"Add"}
                  </button>
                  <button
                    className="tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary"
                    onClick={() => {setAddPayloadModel(false)
                        setFormData(loginCodeListData)
                      }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className={`tw-modal ${historyModel ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>History</div>
                  <button onClick={() => setHistoryModel(false)}>
                    <img src="../assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-5">
                  <table className="w-full">
                    <thead className="text-sm text-light-gray bg-gray-200">
                      <tr>
                        <th className="py-3 px-5">Normal Payout</th>
                        <th className="py-3 px-5">Capping Payout</th>
                        <th className="py-3 px-5">Start Date</th>
                        <th className="py-3 px-5">End Date</th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-100 text-sm text-light-gray">
                      {historyData?.map((details: any) => (
                        <tr className="border-b">
                          <td className="py-3 px-5 text-center">
                            {details.normal_payout}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.capping_payout}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.start_date}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.end_date}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
    )}
        </div >
    )
}

export default CompanyPayoutGrid;
